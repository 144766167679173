<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('title.jadwal') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.doctor') }}:</label>
              <div class="col-lg-6 my-auto">
                <span>{{ jadwal.dokter.nama }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.hari') }} *:</label>
              <div class="col-lg-6 my-auto">
                <jadwal-day
                  :jadwal-id="'jadwal_day_' + jadwal.id"
                  :value="jadwal.hari"
                  :changedValue.sync="jadwal.hari"
                ></jadwal-day>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.jamMulai') }} *:</label>
              <div class="col-lg-6 my-auto">
                <jadwal-time
                  :time-id="'jadwal_start_' + jadwal.id"
                  place-holder="Jam Mulai"
                  :value="jadwal.jamMulai"
                  :changedValue.sync="jadwal.jamMulai"
                  :is-validate="'required'"
                ></jadwal-time>
                <small style="color: red;" v-show="jamSalah">Jam Mulai harus lebih kecil dari Jam Selesai</small>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.jamSelesai') }} *:</label>
              <div class="col-lg-6 my-auto">
                <jadwal-time
                  :time-id="'jadwal_end_' + jadwal.id"
                  place-holder="Jam Selesai"
                  :value="jadwal.jamSelesai"
                  :changedValue.sync="jadwal.jamSelesai"
                  :is-validate="'required'"
                ></jadwal-time>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.durationInMinute') }} :</label>
              <div class="col-lg-6 my-auto">
                  <vue-input-number
                        v-model.number="jadwal.durationInMinute"
                        :placeholder="'Durasi'"
                        :name="'durasi'"
                  ></vue-input-number>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.tanggalMulai') }}:</label>
              <div class="col-lg-6 my-auto">
                <date-picker
                  :changedValue.sync="jadwal.tanggalMulai"
                  :id-date-picker="'jadwal_date_start_' + jadwal.id"
                  :picker-value="jadwal.tanggalMulai"
                  place-holder="Tanggal Mulai"
                  :is-validate="false"
                  start-date="0d"
                  end-date=""
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.tanggalSelesai') }}:</label>
              <div class="col-lg-6 my-auto">
                <date-picker
                  :changedValue.sync="jadwal.tanggalSelesai"
                  :id-date-picker="'jadwal_date_end_' + jadwal.id"
                  :picker-value="jadwal.tanggalSelesai"
                  place-holder="Tanggal Selesai"
                  :is-validate="false"
                  start-date="-1d"
                  end-date=""
                ></date-picker>
              </div>
            </div>
            <div>
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >jadwal di blok:</label>
              <MultiSelectJadwal
              v-model="jadwal.slotIndexBlocked"
              :listJadwals="listJadwals"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import Jadwal from "../../../model/jadwal-model";
import JadwalDay from "../../_select/JadwalDay";
import JadwalTime from "../../_select/JadwalTime";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const ScheduleRepository = RepositoryFactory.get("schedule");
const SweetAlert = Vue.extend(SweetAlertTemplate);
import VueInputNumber from "../../_general/VueInputNumber";
import MultiSelectJadwal from './MultiSelectJadwal'

export default {
  components: {
    DatePicker,
    JadwalDay,
    JadwalTime,
    VueInputNumber,
    MultiSelectJadwal
  },
  data() {
    return {
      jadwal: new Jadwal(),
      jamSalah: false
    };
  },
  props: {
    modalName: { type: String, default:'modal_schedule' },
    modalData: {}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed:{
    listJadwals(){
      let slots = []
      if (this.jadwal.jamMulai && this.jadwal.jamSelesai && this.jadwal.durationInMinute) {
        slots = helpers.arraySlots(this.jadwal.jamMulai, this.jadwal.jamSelesai, this.jadwal.durationInMinute)
      }
      return slots
    },
    watchFor(){
      //gotta watch these 3 for reactiveness
      return [this.jadwal.durationInMinute, this.jadwal.jamMulai, this.jadwal.jamSelesai]
    },
  },
  methods: {
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    updateData: function (data) {
      if (data.slotIndexBlocked == null) data.slotIndexBlocked = []
      this.jadwal = data;
    },
    submitData: function () {	
      var vx = this;	
      if(vx.jadwal.jamSelesai && vx.jadwal.jamMulai){	
        var jamMulai = "00" + vx.jadwal.jamMulai;	
        var jamSelesai = "00" + vx.jadwal.jamSelesai;	
        if(vx.jadwal.jamMulai.length < 6){	
          vx.jadwal.jamMulai = jamMulai.substr(	
            jamMulai.length - 5	
          );	
        };	
        if(vx.jadwal.jamSelesai.length < 6){	
          vx.jadwal.jamSelesai = jamSelesai.substr(	
            jamSelesai.length - 5	
          );	
        };	
        if(vx.jadwal.jamMulai < vx.jadwal.jamSelesai){	
          vx.$validator.validateAll().then(result => {	
            if (result) {	
              if (this.jadwal.id) {	
                let instance = new SweetAlert().showConfirm({	
                  swalTitle: "Apakah Anda yakin?",	
                  swalText: "Perubahan Jadwal akan tersimpan.",	
                  swalType: "info",	
                  onConfirmButton: function () {	
                    blockUI.blockModal();	
                    vx.update(vx.jadwal);	
                  },	
                  onCancelButton: function () {	
                    blockUI.unblockModal();	
                  }	
                });	
              } else {	
                let instance = new SweetAlert().showConfirm({	
                  swalTitle: "Apakah Anda yakin?",	
                  swalText: "Jadwal baru akan ditambahkan.",	
                  swalType: "info",	
                  onConfirmButton: function () {	
                    blockUI.blockModal();	
                    vx.create(vx.jadwal);	
                  },	
                  onCancelButton: function () {	
                    blockUI.unblockModal();	
                  }	
                });	
              }	
            }	
          });	
        } else {
          this.jamSalah = true
          setTimeout(() => {
            this.jamSalah = false
          }, 3000)
        }
      }	
    },
    async update(payload) {
      var vx = this;
      
      await ScheduleRepository.updateSchedule(payload)
        .then(() => {
           let promise = new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
            resolve()
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan Jadwal berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
                vx.$emit("event");
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan Jadwal gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await ScheduleRepository.createSchedule(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
            resolve()
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Jadwal baru berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
                vx.$emit("event");
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Jadwal baru gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  watch:  {
        watchFor: function(newCust, oldCust){

          if ( oldCust[0]  !== null && newCust[1].length < 6 && newCust[2].length < 6) {
            this.jadwal.slotIndexBlocked = []
          }
        }
    }, 
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);

    }
    
  }
};
</script>


